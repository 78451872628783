<template>
  <div
    :class="['vz-async-select', { 'vz-async-select--loading': loading, 'vz-async-select--disabled': disabled }, `vz-async-select-${inputId}`]"
    :data-errors="validateMessage"
  >
    <label class="text-ellipsis">{{ $t(label) }}</label>

    <template v-if="!modelValue || multiselect || !slots['default']">
      <div class="vz-async-select__container">
        <slot name="prefix" />

        <input
          v-bind="!isFocus ? { value: displayText } : {}"
          ref="inputRef"
          v-model="searchValue"
          type="text"
          tabindex="0"
          :placeholder="$t(placeholder)"
          :disabled="disabled"
          :aria-label="t('COMPONENT_LABELS.AUTOCOMPLETE_FIELD', { value: ariaLabel || label || placeholder })"
          @keydown="onQuerySearch"
          @focus="onFocus"
          @blur="onBlur"
          @input="onInput"
        />

        <vz-icon
          v-if="!disabled && clearable && isClearable"
          clickable
          role="button"
          name="svg:xmark"
          size="0.75rem"
          color="primary-900"
          :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLEAR' })"
          @click="$emit('update:model-value', null, vModal)"
        />

        <slot name="append" />
      </div>

      <div v-if="isListShown" class="vz-async-select__list" :style="{ top: `${top}px` }">
        <div
          class="vz-async-select__list-container"
          role="list"
          :style="{ position: 'fixed', width: width + 'px', maxHeight: `calc(${maxHeight}px - 3rem)` }"
        >
          <vz-infinity-scroll
            ref="infinityRef"
            :initial="initial"
            :items="items"
            :callback="callback"
            :payload="internalPayload"
            @update:state="$emit('update:state', $event)"
          >
            <template #no-data>
              <div>{{ $t('DATA.NO_DATA_AVAILABLE') }}</div>
            </template>

            <template #default="{ data }">
              <div
                v-for="(item, index) in data"
                :key="index"
                :class="[
                  `vz-async-select__list-item-${index}`,
                  {
                    'vz-async-select__list-item--active': selectedIndex === index,
                    'vz-async-select__list-item--selected': isSelected(item),
                  },
                ]"
                @click="onSelectFromList(item)"
              >
                <div class="my-2 pa-2">
                  <slot :item="item" :is-selected="isSelected(item)">
                    <div>{{ getTitle(item) }}</div>
                  </slot>
                </div>
              </div>
            </template>
          </vz-infinity-scroll>
        </div>
      </div>
    </template>

    <div
      v-show="validateMessage || externalError"
      :class="['vz-async-select__error', { 'vz-async-select__error--hidden': hideDetails }]"
      role="alert"
    >
      <p v-if="validateMessage" :class="{ 'vz-async-select__error-internal': !isTouched }">{{ $t(validateMessage) }}</p>
      <p v-else-if="externalError">{{ $t(externalError) }}</p>
    </div>

    <div v-if="!hideSelection && vModal?.length" class="vz-async-select__selected d-flex justify-space-between align-center">
      <div class="vz-async-select__badge fill-width">
        <template v-for="(item, index) in vModal" :key="index">
          <slot v-if="$slots['badge']" name="badge" :item="item" :clearable="clearable" :on-clear="() => onClearSelectedItem(index)" />

          <slot v-else :item="item" :index="index" :clearable="clearable" :on-clear="() => onClearSelectedItem(index)" />
        </template>
      </div>

      <vz-icon
        v-if="!disabled && clearable && isClearable && !multiselect"
        class="me-2"
        clickable
        role="button"
        name="svg:xmark"
        size="0.75rem"
        color="primary-900"
        :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLEAR' })"
        @click="$emit('update:model-value', null, vModal)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import { computed, nextTick, type PropType, ref, useSlots, watch } from 'vue';
import { scrollToView, uniqueKey } from '@shared/helpers';
import { useValidator } from '@shared/components/fields/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';
import type { BaseRecords } from '@shared/models';
import { DEFAULT_TABLE_PAGE_SIZE } from '@shared/components/tables/constants/data-table.constants';

const props = defineProps({
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: Array as PropType<Array<any> | any | undefined>, default: undefined },
  multiselect: { type: Boolean, default: false },
  hideSelection: { type: Boolean, default: false },
  autoShown: { type: Boolean, default: false },
  removeLastByBackspace: { type: Boolean, default: false },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  debounce: { type: [String, Number], default: 500 },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  callback: { type: Function as PropType<(...arg: any) => Promise<any>>, required: true },
  payload: { type: Object as PropType<Record<string, any> | undefined>, default: undefined },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  itemText: { type: [String, Function] as PropType<undefined | string | ((value: any) => string)>, default: undefined },
  itemValue: { type: [String, Function] as PropType<undefined | string | ((value: any) => string)>, default: undefined },
  inputKey: { type: String, default: 'search' },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  returnValue: { type: Function as PropType<(value: any) => any | Array<any>>, default: (value: any) => value },
  initial: { type: Object as PropType<BaseRecords<any>>, default: () => ({ page: { size: DEFAULT_TABLE_PAGE_SIZE }, data: null }) },
  items: { type: Array as PropType<BaseRecords<any>['data'] | undefined>, default: undefined },
});

const emit = defineEmits(['update:model-value', 'update:state', 'remove:item', 'select:item', 'search']);

const t = useTranslator();
const slots = useSlots();
const inputId = uniqueKey(props.label);

const isFocus = ref<boolean>(false);
const isListShown = ref<boolean>(false);
const blurTimeout = ref<ReturnType<typeof setTimeout>>();
const debounceTimeout = ref<ReturnType<typeof setTimeout>>(0);
const infinityRef = ref();
const inputRef = ref<HTMLInputElement | undefined>(undefined);
const searchValue = ref<string | null>(null);
const selectedIndex = ref<number>(0);

const vModal = computed({
  get: (): Array<any> => (props.modelValue ? (Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) : []),
  set: (value: Array<any> | any) => emit('update:model-value', value, props.modelValue),
});

const getTitle = (item: any) => {
  if (typeof item !== 'object' || !props.itemText) {
    return item;
  }

  return props.itemText instanceof Function ? props.itemText(item) : item[props.itemText];
};

const displayText = computed((): string | null => {
  if ((slots['default'] || slots['badge']) && !props.hideSelection) {
    return null;
  }

  return vModal.value.map(getTitle).join(', ');
});

const autoCompleteShown = computed((): boolean => isFocus.value && (props.autoShown || !!internalPayload.value));

const { validateMessage, isTouched } = useValidator(
  computed(() => vModal.value),
  computed(() => props.rules),
  props.name || props.label
);

const isClearable = computed(() => !!vModal.value && (!Array.isArray(vModal.value) || vModal.value.length));

const top = ref<number>(0);
const width = ref<number>(0);
const maxHeight = ref<number>(0);
const internalPayload = ref<Record<string, any> | undefined>(props.payload);

const externalError = computed(() => {
  if (!props.errorMessage) {
    return;
  }

  if (typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});

const debounce = (value: string | null) => {
  clearTimeout(debounceTimeout.value);

  debounceTimeout.value = setTimeout(() => {
    internalPayload.value = value ? { [props.inputKey]: value, ...(props.payload || {}) } : props.payload;
    inputRef.value?.focus();
  }, +props.debounce);
};

const onFocus = (): void => {
  if (blurTimeout.value) {
    clearTimeout(blurTimeout.value);
  }

  setTimeout(() => {
    let element = inputRef.value?.parentElement;

    while (element && !element.scrollTop) {
      element = element.parentElement;
    }

    const container = inputRef.value?.parentElement;
    const { top: boundingTop = 0, height: boundingHeight = 0 } = container?.getBoundingClientRect() || {};
    top.value = boundingHeight + 32 - (element?.scrollTop || 0);
    maxHeight.value = Math.max(element?.clientHeight || window.innerHeight - (boundingTop + boundingHeight + 32), 96);
    width.value = container?.getBoundingClientRect().width || 200;
    isFocus.value = true;
  }, 250);
};

const onBlur = (): void => {
  blurTimeout.value = setTimeout(() => {
    searchValue.value = null;
    isFocus.value = false;
  }, 250);
};

const onInput = (): void => debounce(searchValue.value);

const onQuerySearch = (ev: KeyboardEvent): void => {
  switch (ev.key) {
    case 'Backspace':
      if (searchValue.value?.length || !props.removeLastByBackspace) {
        return;
      }

      if (Array.isArray(vModal.value)) {
        emit('update:model-value', vModal.value.slice(0, -1), vModal.value);
      } else {
        emit('update:model-value', null, vModal.value);
      }
      break;
    case 'ArrowDown':
      selectedIndex.value = Math.min(selectedIndex.value + 1, (infinityRef.value?.items?.length || 0) - 1);
      scrollToView(`.vz-async-select__list-item-${selectedIndex.value}`);
      ev.preventDefault();
      break;
    case 'ArrowUp':
      selectedIndex.value = Math.max(selectedIndex.value - 1, 0);
      scrollToView(`.vz-async-select__list-item-${selectedIndex.value}`);
      ev.preventDefault();
      break;
    case 'Enter':
      if (autoCompleteShown.value) {
        const item = (infinityRef.value?.items || [])[Math.max(selectedIndex.value, 0)];
        if (!item) {
          return;
        }

        onSelectFromList(item);
      }

      ev.preventDefault();
      break;
    case 'Escape':
      inputRef.value?.blur();
      ev.preventDefault();
      break;
    default:
      selectedIndex.value = 0;
      scrollToView(`.vz-async-select__list-item-${selectedIndex.value}`);
      break;
  }
};

const onSelectFromList = (item: Record<string, any>): void => {
  searchValue.value = null;
  internalPayload.value = props.payload;

  const value = props.returnValue(item);

  if (!props.multiselect) {
    emit('select:item', item);
    emit('update:model-value', value, vModal.value);

    return;
  }

  const result = (Array.isArray(value) ? value : [value]).reduce((emitValue: Array<any>, currentValue: any) => {
    const isExists = emitValue.map((selectedItem) => JSON.stringify(selectedItem)).includes(JSON.stringify(currentValue));
    emit(isExists ? 'remove:item' : 'select:item', item);

    return isExists ? emitValue.filter((currentItem) => JSON.stringify(currentItem) !== JSON.stringify(currentValue)) : [...emitValue, currentValue];
  }, vModal.value);

  emit('update:model-value', result, vModal.value);
};

const onClearSelectedItem = (index: number) => {
  emit('remove:item', vModal.value[index]);
  emit(
    'update:model-value',
    props.multiselect ? (vModal.value as Array<any>).filter((_, bulkIndex) => bulkIndex !== index) : undefined,
    vModal.value
  );
};

const isSelected = (item: any): boolean => {
  const value = props.returnValue(item);

  return vModal.value.map((selectedItem) => JSON.stringify(selectedItem)).includes(JSON.stringify(value));
};

watch(
  () => autoCompleteShown.value,
  (isShown) => nextTick(() => (isListShown.value = isShown)),
  { immediate: true }
);
</script>

<style lang="scss">
.vz-async-select {
  position: relative;
  display: flex;
  flex-direction: column;

  &--loading {
    .vz-async-select__container {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0.125rem;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background-image: linear-gradient(100deg, var(--color-primary-300) 2%, var(--color-primary-900) 44%, var(--color-primary-300) 98%);
        background-repeat: no-repeat;
        background-size: 35% 100%;
        background-position: 0 0;
        animation: skeletonOverlay 2s linear infinite;
      }

      @keyframes skeletonOverlay {
        0% {
          background-position: -100% 0;
        }
        100% {
          background-position: 200% 0;
        }
      }
    }
  }

  &--disabled {
    .vz-async-select__container {
      color: var(--color-disabled);
      background-color: var(--color-background-disabled);
    }
  }

  &__container {
    display: flex;
    min-height: 36px !important;
    padding: 8px 8px 8px 8px !important;
    border-radius: var(--border-radius-regular);
    align-items: center;
    background-color: var(--color-background-regular);

    > *:not(input) {
      max-width: fit-content;
    }

    input {
      outline: none !important;
      flex-grow: 1;

      &:not(:focus) {
        width: 1ch;
      }
    }

    &:has(input:focus) {
      outline: var(--outline-focus);
    }

    &:has(input:not(:focus)) {
      outline: var(--outline-regular);
    }
  }

  &__list {
    position: absolute;

    &-item {
      &:hover {
        background-color: var(--color-primary-100);
      }

      &--selected {
        background-color: var(--color-background-regular);
      }

      &--active {
        background-color: var(--color-primary-100);
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      background-color: var(--color-background-light);
      border-radius: var(--border-radius-regular);
      padding: 0.5rem;
      max-height: 16rem;
      overflow-y: hidden;
      border: var(--border-regular);
      box-shadow: var(--shadow-hard);
      z-index: 10000;
      margin-bottom: 2.5rem;

      > * {
        padding: 0.25rem;
        width: 100% !important;
      }
    }
  }

  &__badge {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    > * {
      width: fit-content;
    }
  }

  &__selected {
    outline: var(--outline-regular);
    border-radius: var(--border-radius-regular);
    padding: 7px;
    min-height: 36px !important;
  }
}
</style>
