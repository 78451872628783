<template>
  <div class="vz-data-table" :class="{ 'vz-data-table--loading': anyLoading }">
    <vz-search-panel v-if="$slots['search-panel']" :hide-controls="hideSearchControls" @clear="onClear" @search="onSearch">
      <slot name="search-panel" :errors="serverErrors" />
    </vz-search-panel>

    <div v-if="isTableOptionsEnabled" class="vz-data-table__table-options">
      <slot name="table-options" />

      <vz-button
        v-if="tableOptions.includes('ADD') || createCallback"
        text="GENERAL.ADD"
        icon-name="svg:plus"
        @click="!!createCallback ? createCallback?.() : emit('create:item')"
      />
    </div>

    <template v-if="anyItems.length || anyLoading">
      <div ref="tableContainerRef" class="vz-data-table__table-container">
        <table>
          <thead class="text-title-1">
            <tr>
              <td
                v-for="({ minWidth, maxWidth, ...header }, index) in shownHeaders"
                :key="index"
                :style="{ minWidth, maxWidth }"
                :class="`vz-data-table__data-${header.value}`"
              >
                <slot :name="`header-${header.value}`" :header="header">
                  <div
                    :class="{
                      clickable: header.sortable,
                      'd-flex gap-1': header.sortable,
                      'text-center justify-center': header.center,
                      'ps-4': header.center && header.sortable,
                    }"
                    @click="onSort(header.value, header.sortable)"
                  >
                    <slot :name="`label-${header.value}`">
                      {{ $t(header.title) }}
                    </slot>

                    <vz-icon v-if="vSort[header.value]" :name="`svg:sort-${vSort[header.value].type === 1 ? 'up' : 'down'}`" />
                    <vz-icon v-else-if="header.sortable" name="svg:sort" fill-opacity="0.1" />
                  </div>
                </slot>
              </td>

              <td v-if="isItemOptionsEnabled || $slots['item-options']">
                <slot name="header-item-options">{{ $t('GENERAL.ACTIONS') }}</slot>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, itemIndex) in anyItems"
              :key="itemIndex"
              :class="{ 'vz-data-table__table-container--row-clickable': isClickable(item) && readable }"
              @click="readable ? onSelect(item) : undefined"
            >
              <td
                v-for="({ minWidth, maxWidth, ...header }, columIndex) in shownHeaders"
                :key="columIndex"
                :class="[`relative vz-data-table__data-${header.value}`]"
                :style="{ minWidth, maxWidth }"
              >
                <div
                  :class="[
                    'd-flex fill-height',
                    { 'text-center justify-center': header.center },
                    ...(Array.isArray(header.class) ? header.class : [header.class]),
                  ]"
                >
                  <slot :item="item" :item-index="itemIndex" :col-index="columIndex" :name="header.value">
                    {{ get(item, header.value, null) }}
                  </slot>
                </div>
              </td>

              <td v-if="isItemOptionsEnabled || $slots['item-options']" class="vz-data-table__item-options">
                <div class="d-flex gap-2 align-center" @click.stop>
                  <slot name="item-options" :item="item" :item-index="itemIndex" />

                  <vz-button
                    v-if="!item?.isReadonly && (updateCallback || itemOptions.includes('EDIT'))"
                    icon-name="svg:edit"
                    icon-type="regular"
                    aria-label="GENERAL.EDIT"
                    :disabled="disabled"
                    @click="onEdit(item)"
                  />

                  <vz-button
                    v-if="duplicateCallback || itemOptions.includes('DUPLICATE')"
                    icon-name="svg:copy"
                    icon-type="regular"
                    aria-label="GENERAL.DUPLICATE"
                    :disabled="disabled"
                    @click="onDuplicate(item)"
                  />

                  <vz-button
                    v-if="!item?.isReadonly && (deleteCallback || itemOptions.includes('DELETE'))"
                    color="red-900"
                    icon-name="svg:trash"
                    icon-type="regular"
                    aria-label="GENERAL.DELETE"
                    :disabled="disabled"
                    @click="onDelete(item)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="border-top-medium">
        <vz-tab-switcher v-model:index="vPage" class="vz-data-table__pagination" :tabs="totalItems || 0" num-of-shown="10" flat />
      </div>
    </template>

    <div v-else-if="!anyLoading && !hideEmptyState" flat class="vz-data-table vz-data-table--no-data text-title-1">
      <slot name="no-data" :errors="anyErrors">
        <vz-svg-href :name="splashImage" />

        <vz-error-alert v-if="anyErrors?.errorMessage?.length" :errors="anyErrors"></vz-error-alert>

        <p v-else>{{ $t(noDataText || defaultNoDataText) }}</p>

        <vz-button
          v-if="addItemCallback || tableOptions.includes('ADD')"
          class="mt-2 px-10"
          icon-name="svg:plus"
          icon-size="1.125rem"
          :text="addItemLabel"
          @click="addItemCallback || emit('create:item')"
        />
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, type PropType, ref, useSlots } from 'vue';
import type { TableHeader, TableItemOptions, TableOptions } from '@shared/components/tables/models';
import type { ErrorResponse } from '@/shared/services/api-service/models';
import type { SplashName } from '@shared/components/svg-href/svg-splash.type';
import type { BasePagination, BaseRecords } from '@shared/models';
import { get } from 'lodash';
import { useAsync, useServerErrorsMapper } from '@/shared/composables';

type TableSort = Record<string, { type: -1 | 1; fields: Array<string> }>;

const props = defineProps({
  autoFetch: { type: Boolean, default: true },
  flat: { type: Boolean, default: false },
  readable: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  headers: { type: Array as PropType<Array<TableHeader>>, required: true },
  items: { type: Array as PropType<Array<{ [key: string]: any }>>, required: true },
  page: { type: Number, default: 0 },
  sort: { type: Object as PropType<BasePagination['sort']>, default: () => ({}) },
  totalItems: { type: Number, default: 0 },
  loading: { type: Boolean, default: false },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  rowClickable: { type: [Boolean, Function] as PropType<boolean | ((item: any) => boolean)>, default: true },
  idKey: { type: String, default: '' },
  hideEmptyState: { type: Boolean, default: false },
  noDataText: { type: String, default: '' },
  noDataImage: { type: String as PropType<SplashName>, default: 'search-for-results' },
  addItemLabel: { type: String, default: 'GENERAL.ADD' },
  addItemCallback: { type: Function as PropType<(() => unknown | Promise<unknown>) | undefined>, default: undefined },
  noResultsImage: { type: String as PropType<SplashName>, default: 'no-results' },
  tableOptions: { type: Array as PropType<Array<TableOptions>>, default: () => [] },
  itemOptions: { type: Array as PropType<Array<TableItemOptions>>, default: () => [] },
  hideSearchControls: { type: Boolean, default: false },
  createCallback: { type: Function as PropType<(() => unknown | Promise<unknown>) | undefined>, default: undefined },
  exportCallback: {
    type: Function as PropType<((items: Array<{ [key: string]: any }>, headers?: Array<TableHeader>) => unknown | Promise<unknown>) | undefined>,
    default: undefined,
  },
  updateCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  deleteCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  duplicateCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  hasFilters: { type: Boolean, default: false },
  fetchCallback: { type: Function as PropType<((page?: BasePagination) => Promise<BaseRecords<any>>) | undefined>, default: undefined },
});

const emit = defineEmits([
  'search',
  'clear',
  'update:page',
  'update:sort',
  'select:item',
  'update:item',
  'duplicate:item',
  'delete:item',
  'create:item',
  'export:items',
]);

const { call: submitRequest, loading: submitLoading, error: submitError, results: submitResults } = useAsync(props.fetchCallback);

const slots = useSlots();
const searchTriggered = ref<boolean>(false);
const shownHeaders = computed(() =>
  props.headers
    .filter(({ hidden }) => !hidden)
    .map(({ width, maxWidth, minWidth, ...header }) => {
      return { ...header, ...(width ? { maxWidth: maxWidth || width, minWidth: minWidth || width } : { maxWidth, minWidth }) };
    })
);

const isTableOptionsEnabled = computed(
  (): boolean => !!(props.tableOptions.length || props.createCallback || props.exportCallback || slots['table-options'])
);
const isItemOptionsEnabled = computed(() => !!(props.itemOptions.length || props.updateCallback || props.deleteCallback));

const splashImage = computed((): SplashName => {
  if (anyErrors.value?.errorMessage?.length) {
    return 'server-error';
  }

  return searchTriggered.value ? props.noResultsImage : props.noDataImage;
});

const defaultNoDataText = computed(() => {
  if (!slots['search-panel']) {
    return 'DATA.NO_DATA_AVAILABLE';
  }

  return searchTriggered.value || props.hasFilters ? 'DATA.NO_SEARCH_RESULTS' : 'DATA.SEARCH_FOR_RESULTS';
});

const anyItems = computed((): BaseRecords<any>['data'] => props.items || submitResults.value);
const anyErrors = computed(() => props.errors || submitError.value);
const anyLoading = computed(() => props.loading || submitLoading.value);

const serverErrors = useServerErrorsMapper(anyErrors);
const internalPage = ref<number>(0);

const vPage = computed({
  get: (): number => (props.fetchCallback ? internalPage.value : props.page),
  set: (value: number) => {
    const currentPage = props.fetchCallback ? internalPage : props.page;

    if (currentPage === value) {
      return;
    }

    internalPage.value = value;
    submitRequest?.({ index: value });
    emit('update:page', value);
  },
});

const vSort = computed({
  get: (): TableSort => {
    return (props.headers || []).reduce((acc: TableSort, { sortable, value }) => {
      const sort = props.sort || {};

      if (Array.isArray(sortable)) {
        const arrSort = Object.entries(sort);

        const type = arrSort.find(([key]) => {
          return sortable.includes(key);
        })?.[1];

        return type ? { ...acc, [value]: { type: type, fields: sortable } } : acc;
      } else if (sortable === true) {
        return sort[value] ? { ...acc, [value]: { type: sort[value], fields: [value] } } : acc;
      }

      return acc;
    }, {});
  },
  set: (value) => {
    const sort = Object.values(value).reduce((acc: Record<string, -1 | 1>, { type, fields }) => {
      fields
        .filter((key) => !!key)
        .forEach((key) => {
          acc = { ...acc, [key]: type };
        });

      return acc;
    }, {});

    submitRequest?.({ sort });
    emit('update:sort', sort);
  },
});

const onSort = (key: string, sortable?: boolean | Array<string>) => {
  if (!sortable) {
    return;
  }

  if (!vSort.value[key]?.type) {
    vSort.value = { [key]: { type: 1, fields: Array.isArray(sortable) ? sortable : [key] } };
  } else if (vSort.value[key]?.type === 1) {
    vSort.value = { [key]: { type: -1, fields: Array.isArray(sortable) ? sortable : [key] } };
  } else {
    vSort.value = {};
  }
};

const isClickable = (item: any) => {
  return !anyLoading.value && ((props.rowClickable instanceof Function && props.rowClickable(item)) || props.rowClickable === true);
};

const onSelect = (item: any) => {
  if (anyLoading.value || !isClickable(item)) {
    return;
  }

  emit('select:item', props.idKey ? item[props.idKey] : item);
};

const onSearch = async (): Promise<void> => {
  await submitRequest?.();
  searchTriggered.value = true;
  emit('search');
};

const onClear = async (): Promise<void> => {
  searchTriggered.value = false;
  emit('clear');
  nextTick(submitRequest);
};

const onExport = async (): Promise<void> => {
  await props.exportCallback?.(anyItems.value, props.headers);
  emit('export:items');
};

const onEdit = async (item: any): Promise<void> => {
  await props.updateCallback?.(item);
  emit('update:item', item);
};

const onDuplicate = async (item: any): Promise<void> => {
  await props.duplicateCallback?.(item);
  emit('duplicate:item', item);
};

const onDelete = async (item: any): Promise<void> => {
  await props.deleteCallback?.(item);
  emit('delete:item', item);
};

onMounted(() => {
  if (props.autoFetch) {
    onSearch();
  }
});
</script>

<style lang="scss">
.vz-data-table {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__table-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 !important;

    > div {
      height: fit-content;
    }

    table {
      max-width: 100%;
      overflow: auto;
      border-collapse: collapse;

      thead {
        margin-bottom: 2px;
        position: sticky;
        top: 0;
        z-index: 1;
        margin-top: 2px;
        border-bottom: var(--border-regular);
        background-color: var(--color-primary-200);

        tr {
          position: relative;
          width: 100%;
          height: 50px;

          .vz-data-table--loading & {
            &::after {
              content: '';
              position: absolute;
              bottom: -0.125rem;
              left: 0;
              width: 100%;
              height: 0.25rem;
              background-image: linear-gradient(100deg, transparent 5%, var(--color-primary-900) 42.5%, transparent 95%);
              background-repeat: no-repeat;
              background-size: 35% 100%;
              background-position: 0 0;
              animation: skeletonOverlay 2s linear infinite;
            }

            @keyframes skeletonOverlay {
              0% {
                background-position: -100% 0;
              }
              100% {
                background-position: 200% 0;
              }
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: var(--border-regular);

          &:hover {
            .vz-data-table__item-options {
              opacity: 1;
            }
          }

          &.vz-data-table__table-container--row-clickable {
            transition: background-color 0.3s;
            cursor: pointer;

            &:hover {
              background-color: var(--color-mono-200);
            }
          }
        }
      }

      td {
        line-height: 1.5;
        padding: 16px 24px;
      }
    }
  }

  &--no-data {
    margin: 1rem 0;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    flex-grow: 1;

    > svg {
      height: 50%;
      width: 100%;
    }
  }

  &__table-options {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 0.5rem 1rem;
  }

  &__item-options {
    position: relative;
    transition: opacity 0.3s;
    width: 120px;

    @include hover-support {
      opacity: 0;
    }

    :has(.force-action) {
      opacity: 1;
    }
  }

  .tab-switcher-navigation__panel {
    justify-content: center;
    min-height: 1.5rem;

    > div {
      align-items: center;
      justify-content: center;
    }

    &-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      max-width: 3rem;
      aspect-ratio: 2/1;
    }
  }
}
</style>
