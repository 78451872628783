import type { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';

const Calendar = () => import(/* webpackChunkName: "calendar" */ '@/views/calendar/views/calendar.view.vue');
const CalendarGrid = () => import(/* webpackChunkName: "calendar" */ '@/views/calendar/views/calendar-grid.view.vue');
const UpcomingEvents = () => import(/* webpackChunkName: "upcoming-events" */ '@/views/calendar/views/upcoming-events.view.vue');

const calendarRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.PERSONAL.CALENDAR],
  component: Calendar,
  meta: {
    isSignInRequired: true,
    icon: 'svg:calendar',
  },
  children: [
    {
      path: Modules.PATHS[Modules.PERSONAL.CALENDAR],
      name: Modules.PERSONAL.CALENDAR,
      meta: {
        isSignInRequired: true,
        parentName: Modules.PERSONAL.CALENDAR,
        icon: 'svg:calendar',
      },
      component: CalendarGrid,
    },
    {
      path: Modules.PATHS[Modules.PERSONAL.UPCOMING_EVENT],
      name: Modules.PERSONAL.UPCOMING_EVENT,
      meta: {
        isSignInRequired: true,
        parentName: Modules.PERSONAL.CALENDAR,
      },
      component: UpcomingEvents,
    },
  ],
};

export default calendarRoutes;
