import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex flex-column gap-2 my-1" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { key: 0 }

import type { SizeUnit } from '@shared/types';
import { computed, type PropType, StyleValue } from 'vue';
import { useTranslator } from '@/plugins/i18n/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-checkbox',
  props: {
  modelValue: { type: Boolean as PropType<boolean | undefined>, required: true },
  label: { type: String, default: '' },
  size: { type: String as PropType<SizeUnit>, default: '1.5rem' },
  ariaLabel: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  style: { type: Object as PropType<StyleValue | undefined>, default: undefined },
},
  emits: ['update:model-value', 'blur', 'focus'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const t = useTranslator();

const vModel = computed({
  get: (): boolean => !!props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const iconColor = computed(() => {
  if (props.disabled) {
    return 'mono-300';
  }

  return props.modelValue ? 'primary-900' : 'mono-600';
});

const onClick = (): void => {
  if (props.disabled) {
    return;
  }

  emit('update:model-value', !props.modelValue);
};

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(['vz-checkbox text-ellipsis', { 'vz-checkbox--checked': vModel.value, 'vz-checkbox--disabled': __props.disabled }]),
      style: _normalizeStyle(__props.style)
    }, [
      _createElementVNode("input", {
        type: "checkbox",
        value: vModel.value,
        onChange: _withModifiers(onClick, ["stop"])
      }, null, 40, _hoisted_2),
      _createVNode(_component_vz_icon, {
        class: "align-self-start aspect-1-1",
        name: "svg:checkbox",
        role: "checkbox",
        style: _normalizeStyle({ minWidth: __props.size, minHeight: __props.size, maxWidth: __props.size, maxHeight: __props.size }),
        size: __props.size,
        type: vModel.value ? 'solid' : 'regular',
        color: iconColor.value,
        "aria-label": _unref(t)('COMPONENT_LABELS.CHECKBOX', { value: __props.ariaLabel || __props.label })
      }, null, 8, ["style", "size", "type", "color", "aria-label"]),
      _createElementVNode("div", {
        class: _normalizeClass({ 'c-primary-900': vModel.value, 'c-mono-600': !vModel.value, 'c-mono-400': __props.disabled })
      }, [
        _renderSlot(_ctx.$slots, "label", {}, () => [
          (__props.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(__props.label)), 1))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ], 6),
    (vModel.value)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true)
  ]))
}
}

})