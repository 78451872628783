import type { DirectiveBinding } from 'vue';

type Element = HTMLElement & { createTooltip: (event: MouseEvent) => void; removeTooltip: () => void };

const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: ReturnType<typeof setTimeout> | null;

  return function (...args: any[]) {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      func(...args);
    }, wait);
  };
};

const applyTooltipStyle = (el: HTMLElement): void => {
  el.style.cursor = 'default';
};

const setTooltipProps = (event: MouseEvent, tooltipEl: HTMLElement, color: string, text?: string): void => {
  const targetEl = event.target as HTMLElement;

  tooltipEl.innerHTML = text || targetEl?.textContent || '';

  // Calculate tooltip position
  const targetRect = targetEl.getBoundingClientRect();
  const tooltipRect = tooltipEl.getBoundingClientRect();

  let top = targetRect.top - tooltipRect.height - 6; // Above the target element by default
  let left = targetRect.left + (targetRect.width - tooltipRect.width) / 2; // Centered horizontally

  // Adjust if the tooltip is out of the viewport
  if (top < 0) {
    top = targetRect.bottom + 6; // Place below the element if not enough space above
  }
  if (left < 0) {
    left = 6; // Keep some padding from the left edge
  }
  if (left + tooltipRect.width > window.innerWidth) {
    left = window.innerWidth - tooltipRect.width - 6; // Keep some padding from the right edge
  }

  tooltipEl.style.top = `${top}px`;
  tooltipEl.style.left = `${left}px`;
  tooltipEl.style.position = 'fixed';
  tooltipEl.style.zIndex = '1000000000';
  tooltipEl.style.backgroundColor = 'white';
  tooltipEl.style.boxShadow = '0 0 10px 0 rgba(186, 199, 220, 0.71)';
  tooltipEl.style.padding = '5px 10px';
  tooltipEl.style.borderRadius = '10px';
  tooltipEl.style.color = color;
  tooltipEl.style.whiteSpace = 'pre-wrap'; // Allows breaking lines and respects spaces
  tooltipEl.style.maxWidth = '300px'; // Set a maximum width for the tooltip to control line breaks
  tooltipEl.style.wordWrap = 'break-word'; // Break long words if necessary
};

export const vueTooltip = {
  mounted: (el: HTMLElement, binding: DirectiveBinding<string | boolean | { text: string; color: string; isAlwaysOn: boolean }>) => {
    const { text, color, isAlwaysOn } = (() => {
      if (typeof binding.value === 'string') {
        return { text: binding.value, color: 'var(--color-primary-900)', isAlwaysOn: false };
      } else if (typeof binding.value === 'boolean') {
        return { text: '', color: 'var(--color-primary-900)', isAlwaysOn: binding.value };
      }

      return { text: binding.value?.text, color: binding.value?.color, isAlwaysOn: binding.value?.isAlwaysOn };
    })();

    applyTooltipStyle(el);

    if (el?.offsetWidth < el?.scrollWidth || text || isAlwaysOn) {
      const tooltipEl = document.createElement('div');
      const body = document.querySelector('body');

      (el as Element).createTooltip = (event: MouseEvent): void => {
        setTooltipProps(event, tooltipEl, color, text);

        body?.appendChild(tooltipEl);
      };

      (el as Element).removeTooltip = debounce((): void => {
        body?.removeChild(tooltipEl);
      }, 300); // Adjust the debounce time as needed

      el.addEventListener('mouseover', (el as Element).createTooltip);
      el.addEventListener('mouseout', (el as Element).removeTooltip);
    }
  },
  unmounted: (el: HTMLElement) => {
    el.removeEventListener('mouseover', (el as Element).createTooltip);
    el.removeEventListener('mouseout', (el as Element).removeTooltip);

    delete (el as Partial<Element>).createTooltip;
    delete (el as Partial<Element>).removeTooltip;
  },
};
