import { type DirectiveBinding, type ObjectDirective, ref, watch } from 'vue';

export const vueDragResize: ObjectDirective = {
  mounted(
    el: HTMLElement,
    binding: DirectiveBinding<{
      isResizable: boolean;
      isDraggable: boolean;
      isMaximizable: boolean;
      title: string;
      minWidth: number;
      minHeight: number;
    }>
  ) {
    const CORNER_SIZE = 4;
    const HEADER_SIZE = 32; // Maximum drag area from the top

    const { isResizable = true, isDraggable = true, minWidth = el.clientWidth, minHeight = el.clientHeight, isMaximizable = true } = binding.value;
    const mouseX = ref<number>(0);
    const mouseY = ref<number>(0);
    const isDragging = ref<boolean>(false);
    const isResizing = ref<boolean>(false);
    const isMaximized = ref<boolean>(false);
    const cursorPosition = ref<string | null>(null);

    const width = ref<number>(el.clientWidth);
    const height = ref<number>(el.clientHeight);
    const left = ref<number>((window.innerWidth - width.value) / 2); // Center horizontally
    const top = ref<number>((window.innerHeight - height.value) / 2); // Center vertically

    // Store initial size and position
    const initialState = {
      width: width.value,
      height: height.value,
      left: left.value,
      top: top.value,
    };

    // Apply the initial position and size
    el.style.position = 'absolute';
    el.style.left = `${left.value}px`;
    el.style.top = `${top.value}px`;
    el.style.width = `${width.value}px`;
    el.style.height = `${height.value}px`;

    const resizeMinWidth = ref<number>(minWidth || 100);
    const resizeMinHeight = ref<number>(minHeight || 100);
    const resizeMaxWidth = ref<number>(window.innerWidth);
    const resizeMaxHeight = ref<number>(window.innerHeight);

    const updateCursor = (position: string | null = cursorPosition.value) => {
      switch (position) {
        case 'TOP_LEFT':
        case 'BOTTOM_RIGHT':
          document.body.style.cursor = 'nwse-resize';
          break;
        case 'TOP_RIGHT':
        case 'BOTTOM_LEFT':
          document.body.style.cursor = 'nesw-resize';
          break;
        case 'TOP':
        case 'BOTTOM':
          document.body.style.cursor = 'ns-resize';
          break;
        case 'LEFT':
        case 'RIGHT':
          document.body.style.cursor = 'ew-resize';
          break;
        case 'DRAG_AREA':
          document.body.style.cursor = 'move';
          break;
        default:
          document.body.style.cursor = ''; // Reset to default cursor when not dragging or resizing
          break;
      }
    };

    const getCornerPosition = (event: MouseEvent): string | null => {
      const clientX = event.clientX;
      const clientY = event.clientY;
      const rect = el.getBoundingClientRect();

      const elLeft = rect.left + CORNER_SIZE / 2;
      const elRight = rect.right - CORNER_SIZE / 2;
      const elTop = rect.top - CORNER_SIZE / 2;
      const elBottom = rect.bottom - CORNER_SIZE / 2;

      const state = (() => {
        if (Math.abs(clientX - elLeft) <= CORNER_SIZE) {
          if (Math.abs(clientY - elTop) <= CORNER_SIZE) {
            return 'TOP_LEFT';
          } else if (Math.abs(elBottom - clientY) <= CORNER_SIZE) {
            return 'BOTTOM_LEFT';
          } else if (clientY > elTop && clientY < elBottom) {
            return 'LEFT';
          }
        }

        if (Math.abs(elRight - clientX) <= CORNER_SIZE) {
          if (Math.abs(clientY - elTop) <= CORNER_SIZE) {
            return 'TOP_RIGHT';
          } else if (Math.abs(elBottom - clientY) <= CORNER_SIZE) {
            return 'BOTTOM_RIGHT';
          } else if (clientY > elTop && clientY < elBottom) {
            return 'RIGHT';
          }
        }

        if (Math.abs(clientY - elTop) <= CORNER_SIZE && clientX > elLeft && clientX < elRight) {
          return 'TOP';
        }

        if (Math.abs(elBottom - clientY) <= CORNER_SIZE && clientX > elLeft && clientX < elRight) {
          return 'BOTTOM';
        }

        if (clientY > elTop && clientY < elTop + HEADER_SIZE && clientX > elLeft && clientX < elRight) {
          return 'DRAG_AREA';
        }

        return null;
      })();

      if (state === 'DRAG_AREA' && !isDraggable) {
        return null;
      } else if (state !== null && !isResizable) {
        return null;
      }

      return state;
    };

    const handleResize = (deltaX: number, deltaY: number) => {
      const updateValue = (value: number, min: number, max: number): number => {
        if (value < min) return min;
        if (value > max) return max;
        return value;
      };

      const initialWidth = width.value;
      const initialHeight = height.value;
      const minWidth = resizeMinWidth.value;
      const minHeight = resizeMinHeight.value;
      const maxWidth = resizeMaxWidth.value;
      const maxHeight = resizeMaxHeight.value;

      switch (cursorPosition.value) {
        case 'TOP_LEFT':
          width.value = updateValue(width.value - deltaX, minWidth, maxWidth);
          height.value = updateValue(height.value - deltaY, minHeight, maxHeight);
          if (width.value !== initialWidth) left.value += deltaX;
          if (height.value !== initialHeight) top.value += deltaY;
          break;
        case 'TOP_RIGHT':
          width.value = updateValue(width.value + deltaX, minWidth, maxWidth);
          height.value = updateValue(height.value - deltaY, minHeight, maxHeight);
          if (height.value !== initialHeight) top.value += deltaY;
          break;
        case 'BOTTOM_LEFT':
          width.value = updateValue(width.value - deltaX, minWidth, maxWidth);
          height.value = updateValue(height.value + deltaY, minHeight, maxHeight);
          if (width.value !== initialWidth) left.value += deltaX;
          break;
        case 'BOTTOM_RIGHT':
          width.value = updateValue(width.value + deltaX, minWidth, maxWidth);
          height.value = updateValue(height.value + deltaY, minHeight, maxHeight);
          break;
        case 'TOP':
          height.value = updateValue(height.value - deltaY, minHeight, maxHeight);
          if (height.value !== initialHeight) top.value += deltaY;
          break;
        case 'BOTTOM':
          height.value = updateValue(height.value + deltaY, minHeight, maxHeight);
          break;
        case 'LEFT':
          width.value = updateValue(width.value - deltaX, minWidth, maxWidth);
          if (width.value !== initialWidth) left.value += deltaX;
          break;
        case 'RIGHT':
          width.value = updateValue(width.value + deltaX, minWidth, maxWidth);
          break;
        default:
          break;
      }

      // Ensure the element stays within the viewport
      left.value = Math.min(Math.max(left.value, 0), window.innerWidth - width.value);
      top.value = Math.min(Math.max(top.value, 0), window.innerHeight - height.value);

      // Apply the updated dimensions
      el.style.width = `${width.value}px`;
      el.style.height = `${height.value}px`;

      // Apply the updated positions only if the size hasn't reached the minimum limit
      if (width.value > minWidth || cursorPosition.value === 'RIGHT' || cursorPosition.value === 'BOTTOM_RIGHT') {
        el.style.left = `${left.value}px`;
      }

      if (height.value > minHeight || cursorPosition.value === 'BOTTOM' || cursorPosition.value === 'BOTTOM_RIGHT') {
        el.style.top = `${top.value}px`;
      }

      el.style.transform = '';
    };

    const handleDrag = (deltaX: number, deltaY: number) => {
      el.style.transform = '';
      left.value += deltaX;
      top.value += deltaY;

      // Ensure the element stays within the viewport
      left.value = Math.min(Math.max(left.value, 0), window.innerWidth - width.value);
      top.value = Math.min(Math.max(top.value, 0), window.innerHeight - height.value);

      el.style.left = `${left.value}px`;
      el.style.top = `${top.value}px`;
    };

    const handleToggleMaximize = () => {
      if (!isMaximizable || !isResizable) {
        return;
      }

      if (isMaximized.value) {
        // Restore initial size and position
        width.value = initialState.width;
        height.value = initialState.height;
        left.value = initialState.left;
        top.value = initialState.top;
      } else {
        // Maximize to full window size
        initialState.width = width.value;
        initialState.height = height.value;
        initialState.left = left.value;
        initialState.top = top.value;

        width.value = window.innerWidth;
        height.value = window.innerHeight;
        left.value = 0;
        top.value = 0;
      }

      // Apply the new size and position
      el.style.width = `${width.value}px`;
      el.style.height = `${height.value}px`;
      el.style.left = `${left.value}px`;
      el.style.top = `${top.value}px`;

      isMaximized.value = !isMaximized.value;
    };

    const handleWindowResize = () => {
      if (!isResizable) {
        return;
      }

      const wRatio = window.innerWidth / resizeMaxWidth.value;
      const hRatio = window.innerHeight / resizeMaxHeight.value;

      // Update the max width and height based on the new window size
      resizeMaxWidth.value = window.innerWidth;
      resizeMaxHeight.value = window.innerHeight;

      // Adjust element size while respecting minimum size constraints
      let newWidth = width.value * wRatio;
      let newHeight = height.value * hRatio;

      // Ensure the new dimensions do not fall below the minimum sizes
      newWidth = Math.max(newWidth, resizeMinWidth.value);
      newHeight = Math.max(newHeight, resizeMinHeight.value);

      // Update the position proportionally to maintain element's relative position
      const deltaX = (newWidth - width.value) / 2;
      const deltaY = (newHeight - height.value) / 2;

      left.value = Math.min(Math.max(left.value - deltaX, 0), window.innerWidth - newWidth);
      top.value = Math.min(Math.max(top.value - deltaY, 0), window.innerHeight - newHeight);

      // Apply the new dimensions and position
      width.value = newWidth;
      height.value = newHeight;

      el.style.width = `${newWidth}px`;
      el.style.height = `${newHeight}px`;
      el.style.left = `${left.value}px`;
      el.style.top = `${top.value}px`;
    };

    const handleMouseDown = (event: MouseEvent) => {
      cursorPosition.value = getCornerPosition(event);
      updateCursor(cursorPosition.value);

      if (cursorPosition.value === 'DRAG_AREA') {
        isDragging.value = true;
        el.classList.add('vue-drag-resize__dragging');
      } else if (cursorPosition.value !== null) {
        isResizing.value = true;
        el.classList.add('vue-drag-resize__resizing');
      } else {
        return;
      }

      // Set the initial mouse positions to avoid jumping
      mouseX.value = event.clientX;
      mouseY.value = event.clientY;

      // Update left and top values to sync with the current position of the element
      const rect = el.getBoundingClientRect();
      left.value = rect.left;
      top.value = rect.top;

      event.preventDefault(); // Prevent text selection and other default behaviors
      event.stopPropagation();
    };

    const handleDoubleClick = (event: MouseEvent) => {
      if (getCornerPosition(event) === 'DRAG_AREA') {
        handleToggleMaximize();
      }
    };

    const throttleTime = 16; // 60 FPS
    let lastMoveTime = 0;

    const handleMouseMove = (event: MouseEvent) => {
      const now = Date.now();
      if (now - lastMoveTime < throttleTime) return;
      lastMoveTime = now;

      if (isResizing.value || isDragging.value) {
        const deltaX = event.clientX - mouseX.value;
        const deltaY = event.clientY - mouseY.value;

        if (isResizing.value) {
          handleResize(deltaX, deltaY);
        } else if (isDragging.value) {
          handleDrag(deltaX, deltaY);
        }

        mouseX.value = event.clientX;
        mouseY.value = event.clientY;

        event.stopPropagation();
        event.preventDefault();
      } else {
        cursorPosition.value = getCornerPosition(event);
        updateCursor(cursorPosition.value);
      }
    };

    const handleMouseUp = () => {
      isDragging.value = false;
      isResizing.value = false;
      cursorPosition.value = null;
      document.body.style.cursor = ''; // Reset cursor to default
      el.classList.remove('vue-drag-resize__dragging');
      el.classList.remove('vue-drag-resize__resizing');
    };

    const init = () => {
      const { isResizable = true, isDraggable, isMaximizable = true, title, minWidth = el.clientWidth, minHeight = el.clientHeight } = binding.value;

      resizeMinWidth.value = minWidth;
      resizeMinHeight.value = minHeight;
      el.__dragResizeCleanup__?.();

      if (title) {
        const divHeader = document.createElement('div');
        divHeader.innerText = title;
        divHeader.className = 'vue-drag-resize__header';
        divHeader.style.minHeight = `${HEADER_SIZE}px`;
        divHeader.style.maxHeight = `${HEADER_SIZE}px`;
        divHeader.style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
        divHeader.style.display = 'flex';
        divHeader.style.alignItems = 'center';
        divHeader.style.padding = '0 16px';
        divHeader.style.userSelect = 'none';
        divHeader.style.marginBottom = '8px';
        divHeader.style.fontWeight = 'bold';
        el.insertBefore(divHeader, el.firstChild);
      }

      if (isResizable || isDraggable) {
        el.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
      }

      if (isMaximizable) {
        el.addEventListener('dblclick', handleDoubleClick);
      }

      window.addEventListener('resize', handleWindowResize); // Handle window resize
    };

    watch(binding, init, { immediate: true, deep: true });

    // Clean up event listeners when the directive is unmounted
    el.__dragResizeCleanup__ = () => {
      const headerEl = el.querySelector('.vue-drag-resize__header');

      if (headerEl) {
        el.removeChild(headerEl);
      }

      el.removeEventListener('mousedown', handleMouseDown);
      el.removeEventListener('dblclick', handleDoubleClick);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('resize', handleWindowResize); // Remove window resize listener
    };
  },
  unmounted(el: HTMLElement) {
    el.__dragResizeCleanup__?.();

    delete el.__dragResizeCleanup__;
  },
};
