<template>
  <div class="d-flex flex-column gap-2 my-1">
    <label :class="['vz-checkbox text-ellipsis', { 'vz-checkbox--checked': vModel, 'vz-checkbox--disabled': disabled }]" :style="style">
      <input type="checkbox" :value="vModel" @change.stop="onClick" />

      <vz-icon
        class="align-self-start aspect-1-1"
        name="svg:checkbox"
        role="checkbox"
        :style="{ minWidth: size, minHeight: size, maxWidth: size, maxHeight: size }"
        :size="size"
        :type="vModel ? 'solid' : 'regular'"
        :color="iconColor"
        :aria-label="t('COMPONENT_LABELS.CHECKBOX', { value: ariaLabel || label })"
      />

      <div :class="{ 'c-primary-900': vModel, 'c-mono-600': !vModel, 'c-mono-400': disabled }">
        <slot name="label">
          <span v-if="label">{{ $t(label) }}</span>
        </slot>
      </div>
    </label>

    <slot v-if="vModel" />
  </div>
</template>

<script setup lang="ts">
import type { SizeUnit } from '@shared/types';
import { computed, type PropType, StyleValue } from 'vue';
import { useTranslator } from '@/plugins/i18n/helpers';

const props = defineProps({
  modelValue: { type: Boolean as PropType<boolean | undefined>, required: true },
  label: { type: String, default: '' },
  size: { type: String as PropType<SizeUnit>, default: '1.5rem' },
  ariaLabel: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  style: { type: Object as PropType<StyleValue | undefined>, default: undefined },
});

const emit = defineEmits(['update:model-value', 'blur', 'focus']);

const t = useTranslator();

const vModel = computed({
  get: (): boolean => !!props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const iconColor = computed(() => {
  if (props.disabled) {
    return 'mono-300';
  }

  return props.modelValue ? 'primary-900' : 'mono-600';
});

const onClick = (): void => {
  if (props.disabled) {
    return;
  }

  emit('update:model-value', !props.modelValue);
};
</script>

<style lang="scss" scoped>
.vz-checkbox {
  display: inline-flex;
  align-items: center;
  user-select: none;
  gap: 0.25rem;

  input {
    display: none;
  }

  .vz-checkbox {
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-primary-500);
      border: 2px solid var(--color-mono-900);
      border-radius: var(--border-radius-regular);
      overflow: hidden;

      > * {
        transition: opacity 0.3s;
      }
    }

    &__label {
      padding-inline-start: 4px;
    }
  }

  input:checked ~ .vz-checkbox__icon {
    background-color: var(--color-primary-900);

    > * {
      opacity: 1;
    }
  }

  input:not(:checked) ~ .vz-checkbox__icon {
    > * {
      opacity: 0;
    }
  }

  &:hover {
    > input:checked > .vz-checkbox__icon {
      background-color: #ccc;
    }
  }
}
</style>
