// actions
export const SIGN_IN = 'sign-in';
export const SIGN_UP = 'sign-up';
export const SIGN_OUT = 'sign-out';
export const GET_AUTH_SESSION = 'get-auth-session';
export const FORGOT_PASSWORD = 'forgot-password';
export const CHANGE_PASSWORD = 'change-password';
export const METHOD_OTP_VALIDATE = 'method-otp-validate';
export const IMPERSONATE_TO = 'impersonate-to';
export const SAVE_TOKEN = 'save-token';

export const GOOGLE_AUTHORIZATION = 'google-authorization';

// state
export const SESSION = 'session';
export const ASSIGNED = 'assigned-modules';
export const VERIFICATION_EXPIRED_AT = 'verification-expired-at';

// mutations
export const UPDATE_USER = 'update-user';
export const COMPLETE_SIGN_UP = 'complete-sign-up';
export const SET_SESSION = 'set-session';
export const SET_ASSIGNEE = 'set-assigned-modules';
export const SET_VERIFICATION_EXPIRED_AT = 'set-verification-expired-at';
export const LOAD_LOCAL_AUTH_STATE = 'load-local-auth-state';

// getters
export const GET_ASSIGNED_MODULES = 'get-assigned-modules';
export const GET_ASSIGNED_USER_ID = 'get-assigned-user-id';
export const GET_ASSIGNEE_LIST = 'get-assignee-modules';
export const GET_SESSION = 'get-session';
export const GET_AUTH_MODULES = 'get-aut-modules';
export const GET_AUTH_KEY = 'get-auth-key';
export const IS_IMPERSONATED = 'is-impersonated';
export const IS_LOGGED_IN = 'is-logged-in';
export const GET_CURRENCY = 'get-currency';
