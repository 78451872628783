import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineAsyncComponent } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'lobby.view',
  setup(__props) {

const UsersGrid = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/lobby/components/users-grid.vue'));
const VzInstallApp = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/shared/components/vz-install-app.vue'));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(VzInstallApp)),
    _createVNode(_unref(UsersGrid))
  ], 64))
}
}

})