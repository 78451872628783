import { computed, type ComputedRef, type Ref, ref } from 'vue';

type Callback<T = void> = () => T | Promise<T>;
type StepsCallback = Record<string | number, Callback>;

type Config = Partial<{
  validate: Callback<boolean | undefined>;
  steps: ComputedRef<Array<string> | undefined>;
  nextCallbacks: StepsCallback;
  backCallbacks: StepsCallback;
}>;

type ModelStepHandler = {
  activeStep: ComputedRef<string | undefined>;
  isLastStep: ComputedRef<boolean>;
  isFirstStep: ComputedRef<boolean>;
  handleNext: (submitCallback?: Callback, stepsCallbacks?: StepsCallback) => Promise<void>;
  handleBack: (cancelCallback?: Callback) => Promise<void>;
  resetStep: () => void;
  isStepLoading: Ref<boolean>;
};

export const modalStepHandler = (config: Config): ModelStepHandler => {
  const { validate, nextCallbacks, backCallbacks } = config || {};

  const stepIndex = ref<number>(0);
  const stepNames = computed(() => config.steps?.value || []);
  const totalSteps = computed(() => stepNames.value.length || 1);
  const isLastStep = computed(() => stepIndex.value === totalSteps.value - 1);
  const isFirstStep = computed(() => stepIndex.value === 0);
  const activeStep = computed(() => stepNames.value[stepIndex.value]);
  const loading = ref<boolean>(false);

  const executeCallback = async (stepsCallback?: Callback | StepsCallback, index: number = stepIndex.value): Promise<void> => {
    if (!stepsCallback) {
      return;
    }

    try {
      const stepName = stepNames.value[index];
      loading.value = true;

      if (stepsCallback instanceof Function) {
        await stepsCallback();
      } else if (stepName) {
        await stepsCallback?.[stepName]?.();
      }
    } finally {
      loading.value = false;
    }
  };

  const reset = (): void => {
    stepIndex.value = 0;
  };

  const next = async (submitCallback?: Callback): Promise<void> => {
    const isValid = !validate || (await validate());

    if (!isValid) {
      return;
    }

    await executeCallback(nextCallbacks);

    if (isLastStep.value) {
      await executeCallback(submitCallback);

      return;
    }

    stepIndex.value = stepIndex.value + 1;
  };

  const back = async (cancelCallback?: Callback): Promise<void> => {
    if (isFirstStep.value) {
      await cancelCallback?.();

      return;
    }

    await executeCallback(backCallbacks, stepIndex.value - 1);
    stepIndex.value = stepIndex.value - 1;
  };

  return {
    activeStep,
    isLastStep,
    isFirstStep,
    isStepLoading: loading,
    resetStep: reset,
    handleNext: next,
    handleBack: back,
  };
};
