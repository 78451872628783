<template>
  <vz-overlay
    ref="vzOverlayRef"
    v-model:is-open="vIsOpen"
    :class="['vz-modal', ...contentClass]"
    v-bind="$attrs"
    :non-resizable="nonResizable"
    :non-draggable="nonDraggable"
    :title="title ? $t(title) : ''"
    :fit-content="fitContent"
    :size="size"
    :loading="loading"
    :disable-escape="disableEscape"
    :hide-close-button="hideCloseButton"
    :open-event="openEvent"
    :open-callback="openModalCallback"
    :close-event="closeEvent"
    :close-callback="closeCallback"
    :warning-callback="warningCallback"
    @close="$emit('close', $event)"
    @open="$emit('open', $event)"
    @update:is-open="$emit('update:is-open', $event)"
  >
    <template #activator="{ toggle }">
      <slot name="activator" :toggle="toggle" />
    </template>

    <div v-if="subtitle || $slots['header']" class="vz-modal__header">
      <slot name="header" />

      <p v-if="subtitle" class="mt-2 text-body-2">{{ $t(subtitle) }}</p>

      <vz-error-alert class="mb-6" :errors="errors" />
    </div>

    <div ref="formRef" :class="['vz-modal__content', { 'overflow-y-scroll pe-0': overflowY }]">
      <div v-if="openLoading" class="fill-width d-flex align-center justify-center">
        <vz-spinner class="mx-auto" size="96px" />
      </div>

      <template v-else>
        <slot name="default" :close="close" :open="open" :field-errors="fieldErrors" :server-errors="errors" />

        <slot :name="activeStep" :close="close" :open="open" :field-errors="fieldErrors" :server-errors="errors" />
      </template>
    </div>

    <div v-if="!hideActions" class="vz-modal__actions">
      <slot name="actions" :close="close" :open="open">
        <vz-button
          v-if="!hideCancel"
          class="min-width-120"
          :text="isFirstStep ? cancelText : 'GENERAL.BACK'"
          :disabled="isLoading"
          @click="handleBack(vzOverlayRef?.close)"
        />

        <vz-button
          v-if="!hideSubmit"
          class="min-width-120"
          :text="isLastStep ? submitText : 'GENERAL.NEXT'"
          :loading="isLoading"
          @click="handleNext(onSubmit)"
        />
      </slot>
    </div>
  </vz-overlay>
</template>

<script setup lang="ts">
import type { VzOverlayRef } from '@shared/components/overlay/vz-overlay.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import { computed, type PropType, ref } from 'vue';
import { useAsync, useServerErrorsMapper } from '@shared/composables';
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';
import { useFormValidator } from '@shared/components/fields/helpers';
import { modalStepHandler } from '@shared/components/overlay/helpers/modal-step-handler';

const emit = defineEmits(['update:is-open', 'open', 'close', 'submit']);

const props = defineProps({
  nonResizable: { type: Boolean, default: false },
  nonDraggable: { type: Boolean, default: false },
  hideCancel: { type: Boolean, default: false },
  cancelText: { type: String, default: 'GENERAL.CANCEL' },
  hideSubmit: { type: Boolean, default: false },
  submitText: { type: String, default: 'GENERAL.SUBMIT' },
  hideActions: { type: Boolean, default: false },
  overflowY: { type: Boolean, default: true },
  isOpen: { type: Boolean, default: false },
  title: { type: String as PropType<string | undefined>, default: undefined },
  subtitle: { type: String as PropType<string | undefined>, default: undefined },
  fitContent: { type: Boolean, default: false },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  loading: { type: Boolean, default: false },
  disableEscape: { type: Boolean, default: false },
  warningCallback: {
    type: [Boolean, Function] as PropType<boolean | ((payload?: any) => Promise<unknown>)>,
    default: false,
  },
  hideCloseButton: { type: Boolean, default: false },
  openEvent: { type: String as PropType<string | undefined>, default: undefined },
  closeEvent: { type: String as PropType<string | undefined>, default: undefined },
  openCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  closeCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  submitCallback: { type: Function as PropType<() => Promise<unknown> | unknown | undefined>, default: undefined },
  size: { type: String as PropType<keyof typeof VzOverlayEnum | undefined>, default: VzOverlayEnum.SMALL },
  steps: { type: Array as PropType<Array<string> | undefined>, default: undefined },
  nextCallbacks: {
    type: [Object] as PropType<Record<string, () => void | Promise<void>> | undefined>,
    default: undefined,
  },
  backCallbacks: {
    type: [Object] as PropType<Record<string, () => void | Promise<void>> | undefined>,
    default: undefined,
  },
  class: { type: [Object, Array, String] as PropType<string | Record<string, any> | Array<string | Record<string, any>>>, default: () => [] },
});

const contentClass = computed(() => (Array.isArray(props.class) ? props.class : [props.class]));
const isLoading = computed(() => submitLoading.value || isStepLoading.value);
const errors = computed(() => openError.value || vzOverlayRef.value?.errors || props.errors || submitErrors.value || null);

const {
  call: onSubmit,
  loading: submitLoading,
  error: submitErrors,
} = useAsync(
  async () => {
    if (!validate()) {
      return;
    }

    await props.submitCallback?.();
    emit('submit');
  },
  { successCallback: () => vzOverlayRef.value?.close(true) }
);

const {
  call: openModalCallback,
  loading: openLoading,
  error: openError,
} = useAsync(async (payload: any) => {
  resetStep();
  await props.openCallback?.(payload);
});

const formRef = ref<Element | undefined>(undefined);
const vzOverlayRef = ref<VzOverlayRef>(undefined);

const validate = (isSilent?: boolean): boolean => {
  const isValid = useFormValidator(formRef, isSilent);

  return isValid();
};

const vIsOpen = computed({
  get: () => props.isOpen,
  set: (value) => emit('update:is-open', value),
});

const fieldErrors = useServerErrorsMapper(computed(() => vzOverlayRef.value?.errors || props.errors || null));

const { handleNext, handleBack, resetStep, isLastStep, isFirstStep, activeStep, isStepLoading } = modalStepHandler({
  validate,
  steps: computed(() => props.steps),
  nextCallbacks: props.nextCallbacks,
  backCallbacks: props.backCallbacks,
});

const close = (isForce?: boolean) => vzOverlayRef.value?.close(isForce);
const open = () => vzOverlayRef.value?.open();

defineExpose({
  validate,
  close: (isForce?: boolean) => vzOverlayRef.value?.close(isForce),
  open: () => vzOverlayRef.value?.open(),
  errors: computed(() => vzOverlayRef.value?.errors || props.errors),
});
</script>

<style lang="scss">
.vz-modal {
  > :not(.vz-modal__header):not(.vz-modal__actions):not(.vue-drag-resize__header) {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__header {
    position: relative;
    width: 100%;
    padding: 1rem 1rem 0.5rem 1rem;
  }

  &__content {
    overflow: hidden;
    flex-grow: 1;
    padding: 0.5rem 1rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0.5rem 1rem 1rem 1rem;
    position: relative;
    height: fit-content;
    width: 100%;

    @include max-tablet-layout {
      padding-bottom: 2rem;
    }
  }
}
</style>
